/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
// @import url('assets/fonts/https://fonts.googleapis.com/css?family=Montserrat:400,700');
// @import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

// @font-face {
//   font-family: 'Montserrat';
//   src: url(assets/fonts/Montserrat-Regular.ttf) format("truetype");
// }

@font-face {
  font-family: "Quicksand";
  src: url(assets/fonts/Quicksand-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "ChessType";
  src: url(assets/fonts/ChessType.ttf) format("truetype");
}

:root {
  --main-bg-color: powderblue;
  --main-text-color: darkslategray;
  --main-header-color: peru;
  --trakk-blue: #2e3791;
  --trakk-blue-hover: #22286a; // 5% darker
  --trakk-gray-hover: #ddd;
  --taggr-light-blue: #1D9BE3;
  --taggr-light-blue-hover: #0068ab;

  --highlight-color: mediumorchid;
  --light-warning-color: #f4e577;
  --warning-color: firebrick;
  --affirm-color: dodgerblue;
  --dull-color: dimgray;
  --white-color: floralwhite;

  --trakk-dark-blue: #0d1627; // #263238;

  --menu-background: #0d1627; // #263238;
  --menu-link-textcolor: #fff;
  --content-background: #fff;
  --content-text-color: #fff;
  --default-button-background: #0d1627; // #263238;
  --default-button-text-color: #fff;
  --default-button-background-hover: #192125;
  --default-button-text-color-hover: #fff;
  --default-button-background-active: #131a1d;
  --default-button-text-color-active: #fff;
  --default-button-focus: rgba(0, 123, 255, 0.25);

  --form-focus: rgba(0, 123, 255, 0.25);

  --map-background: lightblue;
  --map-cluster-background: rgba(181, 226, 140, 0.6);
  --map-cluster-text-color: #222;

  --color-veh: plum;
  --color-trk: orange;
  --color-acc: lightblue;
  --color-ot: lavender;
  --color-rep: #b2f331;
  --color-grp: khaki;

  --color-selrow: #c3e4ff;
}

.highlight-color {
  color: var(--highlight-color);
}
.warning-color {
  color: var(--warning-color);
}
.affirm-color {
  color: var(--affirm-color);
}
.dull-color {
  color: var(--dull-color);
}
.white-color {
  color: var(--white-color);
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-tab-labels {
  display: unset !important;
  font-family: "Quicksand", "Arial", "Lato", sans-serif;
}

body,
html {
  height: 100%;
  margin: 0 auto;
}

body {
  font-family: "Montserrat";
  font-size: 15px;
  background-color: #fafafa;
}

a {
  text-decoration: none;
  color: DarkSlateBlue;
}

a:visited {
  color: DarkSlateBlue;
}

h2 {
  font-weight: 100;
  font-size: 36px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-family: "Quicksand", "Arial", "Lato", sans-serif;
  color: #555;
  padding-top: 6px;
  margin-top: 0px;
  margin-bottom: 16px;
}

h3 {
  font-weight: 100;
  font-size: 24px;
  letter-spacing: 0.3px;
  // text-transform: uppercase;
  font-family: "Quicksand", "Arial", "Lato", sans-serif;
  color: #555;
  // padding-top: 6px;
  // padding: 0 0 0 8px;
  // padding-left: 16px;
  margin-top: 0px;
  margin-bottom: 4px;
}

textarea {
  padding: 0.2rem 0.4rem;
  resize: none;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 0.4em 1.3em;
  display: grid;
  grid-template-columns: 30% auto;

  a {
    color: var(--white-color);
  }
}

.popup-header {
  margin: 16px 16px 24px 16px !important;
}

.btn {
  background-color: var(--default-button-background);
  color: var(--default-button-text-color);
  padding: 0.4em 0.8em;
  margin: 1rem 0.4rem 0.4rem 0.4rem;
  text-transform: uppercase;
  border: 0px;
  border-radius: 7px;
  font-size: 13px;
  border-radius: 2px;

  &:hover {
    background-color: var(--default-button-background-hover);
    color: var(--default-button-text-color-hover);
    cursor: pointer;
  }
  &.active {
    background-color: var(--default-button-background-active);
    color: var(--default-button-text-color-active);
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem var(--default-button-focus);
  }
}

.btn.deactivated {
  color: #666;
}

section {
  // width: 95%;
  margin: 0 auto;
  padding: 2rem;

  height: calc(100% - 52px);
  overflow: auto;
  width: 100%;
}

input {
  margin: 0.2em 0;
  padding: 0.2rem 0.4rem;
  //width: calc(160px + 10%);
  font-size: 13px;
  min-height: 36px;
}

.swal2-title {
  padding-top: 26px !important;
  font-size: 24px !important;
}

.form-control {
  font-size: 13px;
  background-color: var(--content-background);
  color: var(--content-text-color);
  &:focus {
    background-color: var(--content-background);
    color: var(--content-text-color);
    border-color: var(--form-focus);
    box-shadow: 0 0 0 0.2rem var(--form-focus);
  }
}

select {
  margin: 0.2em 0.2em;
  padding: 0.4em 0.2em;
 // width: calc(160px + 10%);
}

.checkbox {
  width: 20px;
}

p {
  line-height: 1.8;
  letter-spacing: 0.05em;
  max-width: 800px;
}

p::-moz-selection,
p::selection {
  // background-color: var(--menu-background);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

label,
span {
  color: #222;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4em 0.8em;
  .column {
    width: calc(80px + 20%);
  }
}

.centerform {
  // max-width: 700px;
  // margin: auto;
  background-color: #fff;
  padding: 1rem 2rem;
  // border: 1px solid rgba(160, 160, 160, 1);
  // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 8rem;
}

.basic-container {
  margin: 16px;
}

.sectionhead {
  display: flex;
  flex-direction: row;
  img {
    width: 56px;
    height: 56px;
    opacity: 0.8;
  }
  h1 {
    font-size: 42px;
    color: #181818;
    font-weight: 600;
    // font-family: 'Arial';
    padding-top: 12px;
  }
}

.cont {
  max-width: 1600px;
  // margin: 0 1rem 0 1.4rem;
  margin: auto;
}

// ACCORDION
.acchead {
  background-color: #ddd;
  margin-top: 1em;
  &:hover {
    box-shadow: 4px 2px 4px #aaa;
  }
}
.accbody {
  background-color: #ddd; // #abc1e8;
  // max-height: 0;
  // overflow: hidden;
  // transition: max-height 0.2s ease-out;
  // width: 100%;
  // padding: 0 18px;
}
.accord {
  // margin-bottom: 1em;
  font-size: 12px;
}
.rowww {
  display: flex;
  align-items: center;
  padding: 0.4em 0.8em;
  min-height: 2.8em;
}
.boxs {
  flex: 1;
  padding: 0 0.4em;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  // padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin-top: 0.5em;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.active {
  max-height: 288px !important;
}
//
// .panel {
//   padding: 0 18px;
//   background-color: #eee;
//   max-height: 0;
//   overflow: hidden;
//   transition: max-height 0.2s ease-out;
//   width: 100%;
// }

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: "Quicksand", "Arial", "Lato", sans-serif;
}

// SELECTED TEXT
::selection {
  background: #ddedff; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ddedff; /* Gecko Browsers */
}

.detail {
  color: #888;
  font-size: 11px;
}

hr {
  border: 1px solid #ccc;
  margin: 1rem 0;
}

//// MAP STYLES
.RegNoPlate_48 {
  position: absolute;
  bottom: -10px;
  left: -40px;
  border-radius: 5px 5px 5px 5px;
  border: #666666 2px solid;
  padding: 2px;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  min-width: 70px;
  max-width: 88px;
  overflow: hidden;
  max-height: 70px;
  line-height: 16px;
  background: #fafafa;
  color: #333333;
  box-shadow: 1px 1px 1px 0px #cccccc inset, 1px 1px 1px 0px #cccccc,
    -1px -1px 1px 0px #ffffff inset, -1px -1px 1px 0px #ffffff;
  /*text-transform:uppercase;*/
}

.RegNoPlate_32 {
  position: absolute;
  bottom: -8px;
  left: -28px;
  border-radius: 5px 5px 5px 5px;
  border: #666666 2px solid;
  padding: 2px;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  min-width: 48px;
  max-width: 60px;
  overflow: hidden;
  max-height: 48px;
  line-height: 12px;
  background: #fafafa;
  color: #333333;
  box-shadow: 1px 1px 1px 0px #cccccc inset, 1px 1px 1px 0px #cccccc,
    -1px -1px 1px 0px #ffffff inset, -1px -1px 1px 0px #ffffff;
  /*text-transform:uppercase;*/
}

.RegNoPlate_24 {
  position: absolute;
  bottom: -6px;
  left: -21px;
  border-radius: 5px 5px 5px 5px;
  border: #666666 2px solid;
  padding: 2px;
  font-family: sans-serif;
  font-size: 7px;
  font-weight: 400;
  text-align: center;
  min-width: 36px;
  max-width: 45px;
  overflow: hidden;
  max-height: 36px;
  line-height: 9px;
  background: #fafafa;
  color: #333333;
  box-shadow: 1px 1px 1px 0px #cccccc inset, 1px 1px 1px 0px #cccccc,
    -1px -1px 1px 0px #ffffff inset, -1px -1px 1px 0px #ffffff;
  /*text-transform:uppercase;*/
}

.plate-white {
  background: #fafafa;
  color: #333333;
}
.plate-black {
  background: #333333;
  color: #eeeeee;
}
.plate-lightblue {
  background: #99ccff;
  color: #333333;
}
.plate-lightgreen {
  background: #aaffaa;
  color: #333333;
}
.plate-pink {
  background: #ffaaaa;
  color: #333333;
}
.plate-yellow {
  background: #ffff99;
  color: #333333;
}
.plate-blue {
  background: #000099;
  color: #eeeeee;
}
.plate-green {
  background: #009900;
  color: #eeeeee;
}
.plate-red {
  background: #990000;
  color: #eeeeee;
}

.RegNoPlate div {
  overflow: hidden;
}

.MinimalSquare_48 {
  position: absolute;
  bottom: -10px;
  left: -10px;
  border: #000000 2px solid;
  width: 16px;
  overflow: hidden;
  height: 16px;
  background: #fafafa;
}

.MinimalSquare_32 {
  position: absolute;
  bottom: -8px;
  left: -8px;
  border: #000000 2px solid;
  width: 12px;
  overflow: hidden;
  height: 12px;
  background: #fafafa;
}

.MinimalSquare_24 {
  position: absolute;
  bottom: -6px;
  left: -6px;
  border: #000000 2px solid;
  width: 9px;
  overflow: hidden;
  height: 9px;
  background: #fafafa;
}

.sq_black {
  background: #000000;
}
.sq_blue {
  background: #3664cc;
}
.sq_bluegray {
  background: #7596a9;
}
.sq_bordeux {
  background: #ad2929;
}
.sq_brown {
  background: #be9759;
}
.sq_cyan {
  background: #32c3d0;
}
.sq_darkpurple {
  background: #9f91c5;
}
.sq_gray {
  background: #d6d6d6;
}
.sq_green {
  background: #38c916;
}
.sq_indigo {
  background: #becdd9;
}
.sq_lightred {
  background: #f7b5ba;
}
.sq_lime {
  background: #b8fc00;
}
.sq_orange {
  background: #ffba00;
}
.sq_pink {
  background: #f8ccf2;
}
.sq_purple {
  background: #d51af0;
}
.sq_red {
  background: #ff0000;
}
.sq_yellow {
  background: #fcf400;
}

.MinimalSquare div {
  overflow: hidden;
}

.SimpleText {
  position: absolute;
  bottom: -8px;
  left: -21px;
  border-radius: 3px 3px 3px 3px;
  border: #666666 1px solid;
  padding: 2px;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  min-width: 48px;
  max-width: 60px;
  overflow: hidden;
  max-height: 48px;
  line-height: 12px;
  background: #fafafa;
  color: #333333;
  /*text-transform:uppercase;*/
}

.HistoryStartStop {
  position: absolute;
  bottom: 12px;
  left: -22px;
  border-radius: 3px 3px 3px 3px;
  border: #666666 2px solid;
  padding: 2px;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  min-width: 46px;
  overflow: hidden;
  max-height: 38px;
  line-height: 12px;
  background: rgba(238, 127, 0, 0.8);
  color: #fff;
}
.HistoryStartStopArrow {
  position: absolute;
  bottom: 7px;
  left: 2px;
  text-align: center;
  width: 7px;
  overflow: hidden;
  height: 5px;
  background: url(/img/arrow.png);
  color: #fff;
}
.HistoryStartStop h1 {
  font-size: 12px;
  margin: 0;
  line-height: 14px;
}
.HistoryStartStop span {
  font-weight: bold;
  font-size: 10px;
}

.icon-lg {
  font-size: 24px;
}

.tiny-meter {
  // height: 3px;
  // position: absolute;
  // width: 36px;
  // background: #555;
  // box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  // margin-left: -8px;
  // bottom: 12px;
  // border-radius: 1.5px;

  height: 3px;
  /* position: absolute; */
  /* width: calc(100% - 16px); */
  background: #555;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  /* margin-left: -8px; */
  /* bottom: 12px; */
  border-radius: 1.5px;

  span {
    height: 100%;
    width: 50%;
    border-radius: 1.5px;
    display: block;
    background-color: rgb(43, 194, 83);
    position: relative;
    overflow: hidden;
  }
}

.slow-spin {
  -webkit-animation: fa-spin 6s infinite linear;
  animation: fa-spin 6s infinite linear;
}
.fast-spin {
  -webkit-animation: fa-spin 500ms infinite linear;
  animation: fa-spin 500ms infinite linear;
}

.warning-message {
  margin: 4px 12px;
  padding: 12px 16px;
  border: 1px solid black;
  background-color: var(--light-warning-color);
  line-height: 1.5;
  p {
    padding: 0px;
    color: black;
    line-height: 1.5;
  }
  .highlighted {
    font-style: italic;
  }
}


@media (max-width: 800px) {
  body {
    font-size: 13px;
  }
  section {
    // width: 95%;
    padding: 2rem 1rem;
  }
}

@media (max-width: 580px) {
  section {
    // width: 95%;
    // padding: 2rem 0.5rem;
    padding: 1rem 0.2rem;
  }
}

.ggicon {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 2px;
  font-size: 15px;
  .material-icons {
    font-size: 22px;
  }
}

.svg-green > img {
  filter: invert(17%) sepia(36%) saturate(5473%) hue-rotate(98deg)
    brightness(101%) contrast(104%);
}

.svg-red > img {
  filter: invert(25%) sepia(85%) saturate(2167%) hue-rotate(345deg)
    brightness(77%) contrast(102%);
}

.leaflet-control-layers.leaflet-control {
  &.leaflet-control-layers-expanded {
    background: var(--content-background);
  }
  a {
    background-color: var(--default-button-background);
    border-radius: 3px;
  }
  section {
    background: var(--content-background);
    color: var(--content-text-color);
    padding: 0 5px;
    font-size: 16px;
    input[type="radio"] {
      margin: 0;
      padding: 0;
      width: 20px;
    }
    label {
      margin: 10px 0;
      span {
        color: var(--content-text-color);
      }
    }
  }
}
.leaflet-bar .leaflet-control-zoom-out,
.leaflet-bar .leaflet-control-zoom-in {
  background: var(--default-button-text-color);
  color: var(--default-button-text-color);
  border-color: var(--default-button-text-color);
}
div.leaflet-contextmenu {
  background: var(--content-background) !important;
  a.leaflet-contextmenu-item {
    color: var(--content-text-color);
  }
}

div .marker-cluster-small {
  background-color: var(--map-cluster-background);
  div {
    background-color: var(--map-cluster-background);
    span {
      color: var(--map-cluster-text-color);
      font-weight: 900;
    }
  }
}

//REMOVE NUMBER ARROWS
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// GLOBAL BUTTON STYLINGS

// BASE BUTTON
.btn-base {
  //   display: flex;
  // flex-direction: row;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 8px;
  border-radius: 7px;
  border: 0px;
  margin: 2px 4px;
  cursor: pointer;
  width: max-content;
  min-height: 34px;
  .svgee {
    width: 22px !important;
  }
  span {
    padding-left: 8px;
  }
}
.btn-base:hover {
  text-decoration: none;
}
.iconless {
  span {
    padding-left: 0px;
  }
}

// IMAGE BUTTON
.btn-icon {
  padding: 0px 0px;
  .svgee {
    width: 29px !important;
    padding: 2px;
  }
}

// DELETE BUTTON
.btn-del {
  background-color: #bb3322;
  color: #fff;
  p {
    color: white;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  span {
    color: #fff !important;
  }
}
.btn-del:hover {
  background-color: #992c23;
  box-shadow: 0px 0px 2px rgba(79, 23, 20, 0.4);
}

// SAVE BUTTON
.btn-save {
  min-width: 100px;
  background-color: #264a94;
  color: #fff;
  p {
    color: white;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  span {
    color: #fff !important;
  }
}
.btn-save:hover {
  background-color: #1442a0;
  box-shadow: 0px 0px 2px rgba(23, 79, 20, 0.4);
}
.btn-save[disabled],
.btn-del[disabled] {
  background-color: #e4e4e4;
  color: rgb(185, 185, 185);
  p {
    color: rgb(185, 185, 185);
  }
}
.btn-save:hover[disabled] {
  background-color: #e4e4e4;
  box-shadow: 0px 0px 0px rgba(255, 255, 255, 0.4);
  color: rgb(185, 185, 185);
  p {
    color: rgb(185, 185, 185);
  }
}

// OPTIONS BUTTON
.btn-options {
  // background-color: #777790;
  // color: #fff;
  background-color: #505050;
  color: #fff;
  border: 1px solid #aaa;
  // margin: 2px 4px;
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  p,
  span {
    color: white;
  }
}
.btn-options:hover {
  // background-color: #555570 !important;
  background-color: #3a3a3a !important;
  box-shadow: 0px 0px 2px rgba(23, 23, 23, 0.4);
  color: white;
  p,
  span {
    color: white !important;
    text-decoration: none;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
}

.btn-base.btn-options:disabled {
  border: solid 1px #666;
  background-color: #A2A2A2;
  pointer-events: none;
}

.btn-base.btn-options:disabled:hover {
  background-color: #A2A2A2;
  color: #666;
  opacity: 0.6;
}

// ALERT BUTTON
.btn-alert {
  min-width: 100px;
  background-color: #B32;
  color: #fff;
  border-radius: 4px;
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  p,
  span {
    color: white;
  }
}
.btn-alert:hover {
  background-color: #B32;
  box-shadow: 0px 0px 2px rgba(23, 23, 23, 0.4);
  color: white;
  p,
  span {
    color: white !important;
    text-decoration: none;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
}

// CANCEL BUTTON
.btn-cancel {
  min-width: 100px;
  background-color: #A2A2A2;
  color: #fff;
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  p,
  span {
    color: white;
  }

}
.btn-cancel:hover {
  background-color: #A2A2A2;
  p,
  span {
    color: white !important;
    text-decoration: none;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
}

// LARGE BUTTON
.btn-large {
  display: flex;
  flex-direction: column;
  align-items: center;
  .svgee {
    width: 44px !important;
  }
  span {
    padding-left: 0px;
  }
}

// DISABLED BUTTON
.btn-disabled {
  opacity: 0.35;
  cursor: cursor;
  background-color: #dddddd;
  color: #fff;
  border: 1px solid #aaaaaa;
  margin: 2px 4px;
  p {
    color: black;
  }
  .svgee {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(332deg)
      brightness(102%) contrast(105%);
  }
  span {
    color: black !important;
  }
}
.btn-disabled:hover {
  background-color: #dddddd;
  p,
  span {
    color: black;
    text-decoration: none;
  }
  // box-shadow: 0px 0px 2px rgba(23,23,23,0.4);
}

// END GLOBAL BUTTON STYLINGS

.f-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-field-label {
  font-size: 11px;
  text-transform: uppercase;
  color: #444;
  margin: 2px 0 -4px 0;
}

.form-label {
  font-size: 11px !important;
  line-height: 1.3;
  margin: 0.2em;
  text-transform: uppercase;
  color: #444;
  letter-spacing: 0.04rem;
}

// .tooltip-z .tooltip-inner {
//   background-color: #555;
// }

.error-msg {
  font-size: 11px;
  color: red;
}
